import { createSlice } from "@reduxjs/toolkit";
import { getWaitingListUrl } from "../../actions/product/product";

const initialState = {
  getWaitingListUrlLoading: false,
  getWaitingListUrlResponse: null,
  getWaitingListUrlSuccess: null,
  getWaitingListUrlError: false
};

const getWaitingListUrlSlice = createSlice({
  name: "getWaitingListUrlSlice",
  initialState,
  reducers: { resetStateGetWaitingListUrl: (state) => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(getWaitingListUrl.pending, (state) => {
        state.getWaitingListUrlLoading = true;
        state.getWaitingListUrlResponse = null;
        state.getWaitingListUrlSuccess = null;
        state.getWaitingListUrlError = false;
      })
      .addCase(getWaitingListUrl.fulfilled, (state, action) => {
        state.getWaitingListUrlLoading = false;
        state.getWaitingListUrlResponse = action.payload;
        state.getWaitingListUrlSuccess = true;
        state.getWaitingListUrlError = false;
      })
      .addCase(getWaitingListUrl.rejected, (state, action) => {
        state.getWaitingListUrlLoading = false;
        state.getWaitingListUrlResponse = null;
        state.getWaitingListUrlSuccess = false;
        state.getWaitingListUrlError = action.payload;
      });
  }
});

export const { resetStateGetWaitingListUrl } = getWaitingListUrlSlice.actions;
export default getWaitingListUrlSlice.reducer;
