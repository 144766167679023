import { createSlice } from "@reduxjs/toolkit";
import { insertWaitingList } from "../../actions/product/product";

const initialState = {
  insertWaitingListLoading: false,
  insertWaitingListResponse: null,
  insertWaitingListSuccess: null,
  insertWaitingListError: false
};

const insertWaitingListSlice = createSlice({
  name: "insertWaitingListSlice",
  initialState,
  reducers: { resetStateInsertWaitingList: (state) => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(insertWaitingList.pending, (state) => {
        state.insertWaitingListLoading = true;
        state.insertWaitingListResponse = null;
        state.insertWaitingListSuccess = null;
        state.insertWaitingListError = false;
      })
      .addCase(insertWaitingList.fulfilled, (state, action) => {
        state.insertWaitingListLoading = false;
        state.insertWaitingListResponse = action.payload;
        state.insertWaitingListSuccess = true;
        state.insertWaitingListError = false;
      })
      .addCase(insertWaitingList.rejected, (state, action) => {
        state.insertWaitingListLoading = false;
        state.insertWaitingListResponse = null;
        state.insertWaitingListSuccess = false;
        state.insertWaitingListError = action.payload;
      });
  }
});

export const { resetStateInsertWaitingList } = insertWaitingListSlice.actions;
export default insertWaitingListSlice.reducer;
