import { Calendar } from "@phosphor-icons/react";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDateInput = ({ value, onDateApply, resetDate }) => {
  const [currentDate, setCurrentDate] = useState("");
  const [tempSelectedDate, setTempSelectedDate] = useState(value); // Keep Date object
  const [datePickerOpen, setDatePickerOpen] = useState(false); // Toggle DatePicker
  const today = new Date();

  // Update display format for the button
  useEffect(() => {
    if (value) {
      const formattedDate = format(value, "EEEE, dd MMMM yyyy", { locale: id });
      setCurrentDate(formattedDate);
    }
  }, [value]);

  // Apply the selected date for filtering
  const handleApply = () => {
    if (tempSelectedDate) {
      // const formattedForFilter = format(tempSelectedDate, "dd/MM/yyyy"); // Format the date for filtering
      const formattedForFilter = format(tempSelectedDate, "dd/MM/yyyy"); // Format the date for filtering
      onDateApply(tempSelectedDate, formattedForFilter); // Pass the Date object and formatted string
    }
    setDatePickerOpen(false); // Close DatePicker after applying
  };

  // Reset the date selection
  const handleReset = () => {
    setTempSelectedDate(null); // Reset the tempSelectedDate
    setCurrentDate(""); // Reset display to default
    resetDate();
    // Do not close the DatePicker popup
  };

  // Handle date picker change
  const handleDatePickerChange = (date) => {
    setTempSelectedDate(date); // Update only the tempSelectedDate (as a Date object)
  };

  return (
    <div className="relative">
      <button
        className="flex items-center gap-2 px-3 py-2 border-2 border-primarySoftgray rounded-xl"
        onClick={() => setDatePickerOpen(!datePickerOpen)}
      >
        <Calendar className="text-primaryBlack" size={24} />
        <p className="text-12 font-medium">{currentDate || "Pilih Tanggal"}</p>
      </button>

      {datePickerOpen && (
        <div className="absolute top-full mt-2 bg-white shadow-lg rounded z-50">
          <div className="p-4 rounded-xl bg-primaryWhite">
            <DatePicker
              selected={tempSelectedDate}
              onChange={handleDatePickerChange}
              inline
            />
            <div className="flex gap-3 mt-3">
              <button
                onClick={handleApply}
                className="px-4 py-2 bg-primaryOrange text-primaryWhite rounded-lg hover:bg-accentDarkOrange w-full flex items-center justify-center transition-all ease-out duration-200"
              >
                Terapkan
              </button>
              <button
                onClick={handleReset}
                className="px-4 py-2 bg-accentRed text-primaryWhite rounded-lg hover:bg-accentDarkOrange w-full flex items-center justify-center transition-all ease-out duration-200"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDateInput;
