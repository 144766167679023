import { createSlice } from "@reduxjs/toolkit";
import { updateAvailableProduct } from "../../actions/product/product";

const initialState = {
  updateAvailableProductLoading: false,
  updateAvailableProductResponse: null,
  updateAvailableProductSuccess: null,
  updateAvailableProductError: false
};

const updateAvailableProductSlice = createSlice({
  name: "updateAvailableProductSlice",
  initialState,
  reducers: {
    resetStateUpdateAvailableProduct: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAvailableProduct.pending, (state) => {
        state.updateAvailableProductLoading = true;
        state.updateAvailableProductResponse = null;
        state.updateAvailableProductSuccess = null;
        state.updateAvailableProductError = false;
      })
      .addCase(updateAvailableProduct.fulfilled, (state, action) => {
        state.updateAvailableProductLoading = false;
        state.updateAvailableProductResponse = action.payload;
        state.updateAvailableProductSuccess = true;
        state.updateAvailableProductError = false;
      })
      .addCase(updateAvailableProduct.rejected, (state, action) => {
        state.updateAvailableProductLoading = false;
        state.updateAvailableProductResponse = null;
        state.updateAvailableProductSuccess = false;
        state.updateAvailableProductError = false;
      });
  }
});

export const { resetStateUpdateAvailableProduct } =
  updateAvailableProductSlice.actions;
export default updateAvailableProductSlice.reducer;
