import { Dialog, Transition } from "@headlessui/react";
import { X } from "@phosphor-icons/react";
import { Desk } from "@phosphor-icons/react/dist/ssr";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import successState from "../../assets/StateImage/success_state.webp";
import {
  getAllProduct,
  getWaitingListUrl,
  insertWaitingList,
  updateAvailableProduct
} from "../../redux/actions/product/product";
import { resetStateInsertWaitingList } from "../../redux/features/product/insertWaitingListSlice";
import ContinueLoader1 from "../loaders/ContinueLoader1";

export default function SpreadsheetPopup(props) {
  const { isOpen, onClose, produkId, selectedData, stateAvailable } = props;
  const {
    insertWaitingListResponse,
    insertWaitingListLoading,
    insertWaitingListSuccess
  } = useSelector((state) => state.insertWaitingList);

  const {
    getWaitingListUrlResponse,
    getWaitingListUrlLoading,
    getWaitingListUrlSuccess,
    getWaitingListUrlError
  } = useSelector((state) => state.getWaitingListUrl);

  const waitingListUrl = getWaitingListUrlResponse?.data[0]?.spreadsheet_url;

  const [spreadsheetUrl, setSpreadsheetUrl] = useState("");

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      spreadsheetUrl: waitingListUrl || ""
    }
  });

  useEffect(() => {
    if (waitingListUrl) {
      setSpreadsheetUrl(waitingListUrl);
      setValue("spreadsheetUrl", spreadsheetUrl);
    }
  }, [waitingListUrl, setValue]);

  // Function to validate Google Spreadsheet URL
  const isSpreadsheetUrlValid = (url) => {
    const spreadsheetRegex = /https:\/\/docs\.google\.com\/spreadsheets\/d\/.+/;
    return spreadsheetRegex.test(url);
  };

  const submitForm = (dataUpdateProduct) => {
    if (!isSpreadsheetUrlValid(spreadsheetUrl)) {
      alert("Please enter a valid Google Spreadsheet URL.");
      return;
    }

    const formattedData = {
      spreadsheetUrl: dataUpdateProduct.spreadsheetUrl
    };

    dispatch(insertWaitingList(formattedData));
  };

  const handleOkeSuccess = () => {
    dispatch(getAllProduct());
    onClose();
    setTimeout(() => {
      dispatch(resetStateInsertWaitingList());
    }, 1000);
  };

  const handleCheckWaitingList = () => {
    if (isSpreadsheetUrlValid(spreadsheetUrl)) {
      window.open(spreadsheetUrl, "_blank");
    } else {
      alert("Invalid spreadsheet link!");
    }
  };

  useEffect(() => {
    dispatch(resetStateInsertWaitingList());
    dispatch(getWaitingListUrl());
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 font-body" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full ${
                    insertWaitingListSuccess ? "max-w-[600px]" : "max-w-[400px]"
                  }  transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all`}
                >
                  {!(insertWaitingListLoading || insertWaitingListSuccess) && (
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <Desk size={24} />
                        <p className="text-20 font-medium">
                          Spreadsheet Waiting List
                        </p>
                      </div>

                      <button className="" onClick={onClose}>
                        <X size={32} />
                      </button>
                    </div>
                  )}
                  <div className="flex flex-col gap-8 w-full">
                    {insertWaitingListLoading ? (
                      <div className="w-full h-full flex items-center justify-center">
                        <ContinueLoader1 />
                      </div>
                    ) : insertWaitingListSuccess ? (
                      <div className="flex flex-col items-center justify-center text-center gap-10">
                        <img
                          className="w-[320px] h-[320px]"
                          src={successState}
                        />
                        <div className="flex flex-col gap-3">
                          <p className="text-20 font-semibold">
                            Berhasil Mengedit Meja!
                          </p>
                          <p className="text-20">
                            Cek lebih lanjut pada tabel Meja Billiard
                          </p>
                        </div>
                        <button
                          onClick={handleOkeSuccess}
                          className="flex items-center justify-center w-full rounded-xl bg-primaryOrange text-white py-2"
                        >
                          Oke
                        </button>
                      </div>
                    ) : (
                      <form
                        onSubmit={handleSubmit(submitForm)}
                        className="w-full"
                      >
                        <div className="flex flex-col gap-6 py-10">
                          <div className="flex flex-col gap-1 text-primaryDarkgray">
                            <h3>Link Spreadsheet</h3>
                            <input
                              className="bg-primary3 border text-black border-primaryDarkgray focus:outline-none rounded-lg w-full h-10 px-4"
                              type="text"
                              id="spreadsheetUrl"
                              {...register("spreadsheetUrl", {
                                required: true
                              })}
                              value={spreadsheetUrl}
                              onChange={(e) =>
                                setSpreadsheetUrl(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="flex w-full items-center justify-center gap-3">
                          <button
                            id="submitFormButton"
                            className="flex items-center justify-center bg-primaryOrange py-3 text-center text-white rounded-lg w-full hover:bg-accentDarkOrange transition duration-300 delay-100"
                            aria-label="Toggle Submit"
                            type="submit"
                          >
                            Simpan
                          </button>
                          <button
                            type="button"
                            className={`flex items-center justify-center bg-primaryWhite py-3 text-center border ${
                              spreadsheetUrl?.length === 0 ||
                              spreadsheetUrl === ""
                                ? "border-primaryDarkgray text-primaryDarkgray "
                                : "border-primaryOrange text-primaryOrange hover:bg-accentSoftOrange2"
                            } rounded-lg w-full  transition duration-300 delay-100`}
                            onClick={handleCheckWaitingList}
                            disabled={
                              spreadsheetUrl?.length === 0 ||
                              spreadsheetUrl === ""
                            }
                          >
                            Cek Waiting List
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
