// @ts-nocheck
import {
  CaretLeft,
  CaretRight,
  CaretUpDown,
  FileXls,
  MagnifyingGlass,
  Plus,
  TrashSimple
} from "@phosphor-icons/react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import CustomDateInput from "./CustomDateInput"; // Import the custom input component
import { CheckCircle, XCircle } from "@phosphor-icons/react/dist/ssr";

export default function AdvancedTable({
  columns,
  data,
  tableName,
  handleOpenAddtablePopup,
  handleDeleteSelected,
  idNameToSelect,
  selectedId,
  setSelectedId,
  handleAllAvaialable,
  handleAllUnavaialable
}) {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const [selectedRows, setSelectedRows] = useState(new Set());

  console.log("ayayaya:", data);

  const [selectAll, setSelectAll] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "data.xlsx");
  };

  console.log("selectedRows:", selectedRows);
  console.log("data ini:", data);

  const handleRowSelect = (rowId, index) => {
    const id = data?.[index]?.[idNameToSelect];

    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      const newselectedIds = new Set(selectedId);

      if (newSelectedRows.has(rowId)) {
        newSelectedRows.delete(rowId);
        newselectedIds.delete(id);

        setSelectAll(false); // Set selectAll to false when unselecting a row
      } else {
        newSelectedRows.add(rowId);
        newselectedIds.add(id);
      }

      setSelectedId(Array.from(newselectedIds));

      return newSelectedRows;
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows(new Set());
      setSelectedId([]);
    } else {
      const allRowIds = new Set(filteredData.map((_, index) => index));
      const allIds = filteredData.map((item) => item?.[idNameToSelect]);

      setSelectedRows(allRowIds);
      setSelectedId(allIds);
    }
    setSelectAll(!selectAll);
  };

  console.log("selectedId", selectedId);

  const handleDeleteById = () => {};

  const filterDataByDate = () => {
    if (!selectedDate) {
      setFilteredData(data);
      return;
    }

    const filtered = data.filter((item) => {
      // Extract the date portion from the "tanggalBooking" string
      const datePart = item.tanggalBooking.split(" ")[0]; // "20/09/2024"

      // Convert the date string "DD/MM/YYYY" into a Date object
      const [day, month, year] = datePart.split("/");
      const itemDate = new Date(`${year}-${month}-${day}`); // Convert to YYYY-MM-DD format

      // Check if itemDate is a valid date
      if (isNaN(itemDate)) {
        return false;
      }

      return (
        itemDate.getFullYear() === selectedDate.getFullYear() &&
        itemDate.getMonth() === selectedDate.getMonth() &&
        itemDate.getDate() === selectedDate.getDate()
      );
    });

    setFilteredData(filtered);
  };

  useEffect(() => {
    filterDataByDate();
  }, [selectedDate, data]);

  console.log("yas data:", data);
  console.log("yas selectAll:", selectAll);

  const columnsWithImage = useMemo(
    () =>
      columns.map((column) => {
        if (column.header === "Foto Meja") {
          return {
            ...column,
            cell: ({ row }) => (
              <div className="w-full flex items-center justify-center">
                {" "}
                <img
                  src={`data:${row.original.mime_type};base64,${row.original.foto_product}`}
                  alt="Foto Meja"
                  className="w-16 h-14 rounded-xl object-cover"
                />
              </div>
            )
          };
        }
        return column;
      }),
    [columns]
  );

  let selectColumn = null;

  if (tableName !== "Booking List") {
    selectColumn = {
      header: ({ table }) => (
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
      ),
      id: "select",
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={selectedRows.has(row.index)}
          onChange={() => handleRowSelect(row.index, row.index)}
        />
      )
    };
  }
  const table = useReactTable({
    data: filteredData,
    columns: useMemo(
      () =>
        selectColumn ? [selectColumn, ...columnsWithImage] : columnsWithImage,
      [columnsWithImage, selectedRows, selectAll]
    ),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter: filtering,
      pagination
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    onPaginationChange: setPagination
  });

  const [tempSelectedDate, setTempSelectedDate] = useState(null);

  const resetDate = () => {
    setTempSelectedDate(null);
    setSelectedDate(null);
  };

  const handleDateApply = (dateObject, formattedDate) => {
    setSelectedDate(dateObject); // Keep the Date object for manipulation
    console.log("Filtered by date (formatted):", formattedDate);
    // Now you can use `selectedDate` (Date object) for logic, e.g., getFullYear(), etc.
  };

  console.log("table.getRowModel().rows:", table.getRowModel().rows); // Debugging: Check the rows generated by the table
  const filteredTableData = table.getRowModel().rows;
  const allAvailable = filteredTableData?.every(
    (data) => data?.original?.is_available
  );
  const allUnavailable = filteredTableData?.every(
    (data) => !data?.original?.is_available
  );

  console.log("asyamsong1:", allAvailable);
  console.log("asyamsong2:", allUnavailable);
  return (
    <>
      <div className="w-full max-h-full p-5 bg-primaryWhite rounded-xl border border-primarySoftgray">
        <div className="flex flex-col gap-5">
          <div className="w-full divide-y">
            <p className="text-16 font-medium">{tableName}</p>
          </div>
          <div className="flex items-center gap-4 w-full">
            <div className="flex items-center gap-3 text-12">
              <label className="font-normal">Tampilkan</label>
              <select
                className="font-medium px-2 py-3 rounded-xl border border-primarySoftgray"
                value={pagination.pageSize}
                onChange={(e) =>
                  setPagination({
                    ...pagination,
                    pageSize: Number(e.target.value)
                  })
                }
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex-1 w-fit min-w-[316px] border flex items-center gap-3 px-6 py-2 rounded-xl">
              <MagnifyingGlass size={24} />
              <input
                type="text"
                className="w-full focus:outline-none"
                placeholder="Cari Data"
                value={filtering}
                onChange={(e) => setFiltering(e.target.value)}
              />
            </div>
            {tableName === "Meja Billiard" ? (
              <>
                {" "}
                <button
                  onClick={handleOpenAddtablePopup}
                  className="flex items-center gap-2 px-3 py-2 border-2 border-primarySoftgray rounded-xl"
                >
                  <Plus className="text-accentGreen" size={24} />
                  <p className="text-12 font-medium text-accentGreen">
                    Tambah Meja
                  </p>
                </button>
                {allAvailable ? (
                  <button
                    onClick={handleAllAvaialable}
                    className="flex items-center gap-2 px-3 py-2 border-2 border-primarySoftgray rounded-xl"
                  >
                    <XCircle className="text-primaryOrange" size={24} />
                    <p className="text-12 font-medium text-primaryOrange">
                      Tutup Semua Meja
                    </p>
                  </button>
                ) : allUnavailable ? (
                  <button
                    onClick={handleAllUnavaialable}
                    className="flex items-center gap-2 px-3 py-2 border-2 border-primarySoftgray rounded-xl"
                  >
                    <CheckCircle className="text-primaryOrange" size={24} />
                    <p className="text-12 font-medium text-primaryOrange">
                      Buka Semua Meja
                    </p>
                  </button>
                ) : (
                  <button
                    onClick={handleAllAvaialable}
                    className="flex items-center gap-2 px-3 py-2 border-2 border-primarySoftgray rounded-xl"
                  >
                    <XCircle className="text-primaryOrange" size={24} />
                    <p className="text-12 font-medium text-primaryOrange">
                      Tutup Semua Meja
                    </p>
                  </button>
                )}
              </>
            ) : (
              <div className="flex items-center gap-3">
                <CustomDateInput
                  value={selectedDate}
                  onDateApply={handleDateApply}
                  resetDate={resetDate}
                  data={data}
                  setFilteredData={setFilteredData}
                />
                <button
                  className="flex items-center gap-2 px-3 py-2 border-2 border-primarySoftgray rounded-xl"
                  onClick={handleExport}
                >
                  <FileXls size={24} />
                  <p className="text-12 font-medium">Export ke Spreadsheet</p>
                </button>
              </div>
            )}
            {(tableName === "Meja Billiard" ||
              tableName === "Transaksi Masuk") && (
              <button
                onClick={handleDeleteSelected}
                className="flex items-center gap-2 px-3 py-2 border-2 border-primarySoftgray rounded-xl "
                disabled={selectedId?.length === 0}
              >
                <TrashSimple className="text-accentRed" size={24} />
                <p className="text-12 font-medium text-accentRed">
                  Hapus Semua
                </p>
              </button>
            )}
          </div>
        </div>
        <table className="w-full divide-y text-center text-12 font-medium">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    onClick={header.column.getToggleSortingHandler()}
                    className="py-5"
                    key={header.id}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {
                      {
                        asc: <CaretUpDown />,
                        desc: <CaretUpDown />
                      }[header.column.getIsSorted() ?? null]
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td className="py-5" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex items-center gap-3 text-12">
          <button
            className={`flex items-center justify-center transition ease-in-out duration-200 ${
              table.getCanPreviousPage()
                ? "bg-primaryWhite"
                : "bg-primarySoftgray text-primaryDarkgray"
            }  w-6 h-6  rounded-lg shadow-xl`}
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
          >
            <CaretLeft size={16} />
          </button>
          {table.getPageOptions().map((page, index) => (
            <button
              key={index}
              onClick={() => table.setPageIndex(page)}
              className={`flex items-center justify-center text-primaryBlack w-6 h-6 transition ease-in-out duration-200 ${
                table.getState().pagination.pageIndex === page
                  ? " bg-primaryBlack text-primaryWhite"
                  : "bg-primaryWhite"
              } rounded-lg shadow-xl `}
            >
              {page + 1}
            </button>
          ))}
          <button
            className={`flex items-center justify-center transition ease-in-out duration-200 ${
              table.getCanNextPage()
                ? "bg-primaryWhite"
                : "bg-primarySoftgray text-primaryDarkgray"
            }  w-6 h-6  rounded-lg shadow-xl`}
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            <CaretRight size={16} />
          </button>
        </div>
      </div>
    </>
  );
}
