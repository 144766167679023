import { Dialog, Transition } from "@headlessui/react";
import { PencilSimple, UploadSimple, X } from "@phosphor-icons/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import successState from "../../assets/StateImage/success_state.webp";
import {
  getAllProduct,
  updateAvailableProduct,
  updateProduct
} from "../../redux/actions/product/product";
import { resetStateAddProduct } from "../../redux/features/product/addProductSlice";
import { resetStateUpdateProduct } from "../../redux/features/product/updateProductSlice";
import ContinueLoader1 from "../loaders/ContinueLoader1";
import { Desk } from "@phosphor-icons/react/dist/ssr";
import { resetStateUpdateAvailableProduct } from "../../redux/features/product/updateAvailableProductSlice";

export default function UpdateAvailablePopup(props) {
  const { isOpen, onClose, produkId, selectedData, stateAvailable, stateOpen } =
    props;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateEnabled, setEndDateEnabled] = useState(false); // Tracks if the end date is enabled

  const [successStateStatus, setSuccessStateStatus] = useState(null);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      startDate: selectedData?.startDate || "",
      endDate: selectedData?.endDate || ""
    }
  });

  const {
    updateAvailableProductResponse,
    updateAvailableProductLoading,
    updateAvailableProductSuccess
  } = useSelector((state) => state.updateAvailableProduct);

  // Function to format the date into MM/DD/YYYY
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };
  console.log("adakah row id:", produkId);

  const submitForm = (dataUpdateProduct) => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const formattedDate = `${formattedStartDate}${
      endDateEnabled ? "" : " " + "-" + " " + formattedEndDate
    }`; // Empty if end date is disabled

    // Create the object with the correct format
    const formattedData = {
      produkId: produkId || [""],
      tanggal: formattedDate,
      endDate: endDateEnabled,
      available: stateOpen === false ? false : stateAvailable
    };

    if (stateOpen === false) {
      setSuccessStateStatus("edit");
    } else {
      setSuccessStateStatus("reclose");
    }
    console.log("Formatted Data:", formattedData);

    // Dispatch the update action
    dispatch(updateAvailableProduct(formattedData));
  };

  useEffect(() => {
    if (selectedData) {
      setValue("startDate", selectedData.startDate || "");
      setValue("endDate", selectedData.endDate || "");
      setStartDate(selectedData.startDate || "");
      setEndDate(selectedData.endDate || "");
    }
  }, [selectedData, setValue]);

  const handleOkeSuccess = () => {
    dispatch(getAllProduct());
    onClose();
    setTimeout(() => {
      dispatch(resetStateUpdateAvailableProduct());
    }, 1000);
  };

  useEffect(() => {
    dispatch(resetStateUpdateAvailableProduct());
  }, [isOpen]);

  const handleReopenTable = () => {
    setSuccessStateStatus("reopen");
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const formattedDate = `${formattedStartDate}${
      endDateEnabled ? "" : " " + "-" + " " + formattedEndDate
    }`; // Empty if end date is disabled
    const formattedData = {
      produkId: produkId || [""],
      tanggal: formattedDate,
      endDate: endDateEnabled,
      available: true
    };

    dispatch(updateAvailableProduct(formattedData));
    // onClose(); // Close the popup after dispatching
  };

  if (!isOpen) return null;
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 font-body" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full ${
                    updateAvailableProductSuccess
                      ? "max-w-[600px]"
                      : "max-w-[400px]"
                  }  transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all`}
                >
                  {!(
                    updateAvailableProductLoading ||
                    updateAvailableProductSuccess
                  ) && (
                    <div className="flex items-center  justify-between">
                      <div className="flex items-center gap-3">
                        <Desk size={24} />
                        <p className="text-20 font-medium">Status Meja</p>
                      </div>

                      <button
                        className=""
                        onClick={() => {
                          onClose();
                        }}
                      >
                        <X size={32} />
                      </button>
                    </div>
                  )}
                  <div className="flex flex-col gap-8 w-full ">
                    {updateAvailableProductLoading ? (
                      <div className="w-full h-full flex items-center justify-center">
                        <ContinueLoader1 />
                      </div>
                    ) : updateAvailableProductSuccess ? (
                      <div className="flex flex-col items-center justify-center text-center gap-10">
                        <img
                          className="w-[320px] h-[320px]"
                          src={successState}
                        />
                        <div className="flex flex-col gap-3">
                          <p className="text-20 font-semibold">
                            {successStateStatus === "edit"
                              ? "Berhasil Menutup Meja!"
                              : successStateStatus === "reopen"
                              ? "Berhasil Membuka Meja!"
                              : successStateStatus === "reclose"
                              ? "Berhasil Menutup Meja!"
                              : ""}
                            {/* {successStateStatus} */}
                          </p>
                          <p className="text-20">
                            Cek lebih lanjut pada tabel Meja Billiard
                          </p>
                        </div>
                        <button
                          onClick={handleOkeSuccess}
                          className="flex items-center justify-center w-full rounded-xl bg-primaryOrange text-white py-2"
                        >
                          Oke
                        </button>
                      </div>
                    ) : (
                      <form
                        onSubmit={handleSubmit(submitForm)}
                        className="w-full"
                      >
                        <div className="flex flex-col gap-6 py-10">
                          <div className="flex flex-col gap-1 text-primaryDarkgray">
                            <h3>Tanggal Mulai Unavailable</h3>
                            <input
                              className="bg-primary3 border text-black border-primaryDarkgray focus:outline-none rounded-lg w-full h-10 px-4"
                              type="date"
                              id="startDate"
                              {...register("startDate", { required: true })}
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              required
                            />{" "}
                            <div className="flex items-center gap-2">
                              <input
                                type="checkbox"
                                id="endDateEnabled"
                                checked={endDateEnabled}
                                onChange={(e) =>
                                  setEndDateEnabled(e.target.checked)
                                }
                              />
                              <label
                                className="text-black"
                                htmlFor="endDateEnabled"
                              >
                                Terapkan tanpa tanggal akhir
                              </label>
                            </div>
                          </div>
                          <div className="flex flex-col gap-1 text-primaryDarkgray">
                            <h3>Tanggal Berakhir Unavailable</h3>
                            <input
                              className="bg-primary3 border text-black border-primaryDarkgray focus:outline-none rounded-lg w-full h-10 px-4"
                              type="date"
                              id="endDate"
                              {...register("endDate")}
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              disabled={endDateEnabled} // Disable when checkbox is true
                            />
                          </div>
                        </div>
                        <div className="flex w-full items-center justify-center gap-3">
                          <button
                            id="submitFormButton"
                            className="flex items-center justify-center bg-primaryOrange py-3 text-center text-white rounded-lg w-full hover:bg-accentDarkOrange transition duration-300 delay-100"
                            aria-label="Toggle Submit"
                            type="submit"
                          >
                            Simpan
                          </button>{" "}
                          {stateAvailable ? (
                            <div
                              onClick={handleReopenTable}
                              className="flex items-center cursor-pointer justify-center bg-primaryWhite py-3 text-center border border-accentGreen text-accentGreen rounded-lg w-full hover:bg-accentSoftGreen transition duration-300 delay-100"
                            >
                              Buka Kembali Meja
                            </div>
                          ) : (
                            <button
                              onClick={onClose}
                              id="cancelButton"
                              className="flex items-center justify-center bg-primaryWhite py-3 text-center border border-accentRed text-accentRed rounded-lg w-full hover:bg-accentSoftOrange2 transition duration-300 delay-100"
                            >
                              Batalkan
                            </button>
                          )}
                        </div>
                      </form>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
