import {
  Envelope,
  InstagramLogo,
  MapPin,
  Phone,
  WhatsappLogo
} from "@phosphor-icons/react";
import { YoutubeLogo } from "@phosphor-icons/react/dist/ssr";
import React from "react";
import mapsDummy from "../assets/Address/card_maps.webp";
import { Link } from "react-router-dom";
const Address = () => {
  const address =
    "D'Palace Billiard 4, Jl. Radin Inten II No.98, RT.4/RW.7, Duren Sawit, Kec. Duren Sawit, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13440"; // Your desired address
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;
  return (
    <div id="kontak" className="flex flex-col items-center gap-10 px-5">
      <div className="flex flex-col gap-5 items-center justify-center">
        <h1 className="font-bold text-24 md:text-32 text-primaryBlack pt-32">
          ALAMAT
        </h1>

        <div className="flex flex-col xl:flex-row gap-3 lg:gap-5 h-fit">
          <div className="flex flex-col gap-10 w-[440px] md:w-[660px] max-h-[360px] rounded-xl bg-primaryWhite p-8">
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center p-2 rounded-full bg-accentSoftOrange2 text-primaryOrange">
                <Phone size={32} />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-18 md:text-24 font font-semibold">
                  Telepon
                </span>
                <span className="text-14 md:text-16">087865978186</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center p-2 rounded-full bg-accentSoftOrange2 text-primaryOrange">
                <Envelope size={32} />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-18 md:text-24 font font-semibold">
                  Instagram
                </span>
                <span className="text-14 md:text-16">@dpalacebilliard</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center p-2 rounded-full bg-accentSoftOrange2 text-primaryOrange">
                <MapPin size={32} />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-18 md:text-24 font font-semibold">
                  Alamat
                </span>
                <span className="text-14 md:text-16">
                  4, Jl. Radin Inten II No.98, RT.4/RW.7, Duren Sawit, Kec.
                  Duren Sawit, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta
                  13440
                </span>
              </div>
            </div>
            {/* <div className="flex flex-col gap-3">
              <span className="text-18 md:text-24 font font-semibold">
                Sosial Media
              </span>
              <div className="flex gap-6">
                <div className="flex items-center justify-center p-2 rounded-full bg-accentSoftOrange2 text-primaryOrange">
                  <InstagramLogo size={48} />
                </div>
                <div className="flex items-center justify-center p-2 rounded-full bg-accentSoftOrange2 text-primaryOrange">
                  <YoutubeLogo size={48} />
                </div>
                <div className="flex items-center justify-center p-2 rounded-full bg-accentSoftOrange2 text-primaryOrange">
                  <WhatsappLogo size={48} />
                </div>
              </div>
            </div> */}
          </div>
          <div className="flex flex-col gap-5 items-center max-h-[360px]">
            {/* <img
              className="w-[600px] h-[456px]"
              src={mapsDummy}
              alt="GMaps Dummy"
            />{" "} */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1567303009633!2d106.9200726!3d-6.2430656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698ddb22b0e4c1%3A0xf59c461af6291df3!2sD&#39;Palace%20Billiard!5e0!3m2!1sen!2sid!4v1727412389467!5m2!1sen!2sid"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <a
              href={mapsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center w-full justify-center bg-primaryOrange px-3 py-2 text-white hover:bg-accentDarkOrange transition-all ease-in-out duration-200 rounded-md"
            >
              Buka di Google Maps
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
