import { Calendar, CaretLeft, CaretRight, Clock } from "@phosphor-icons/react";
import { addDays, format, isSameDay, startOfWeek } from "date-fns";
import { id } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "./TimePicker";

const TimeFilter = React.memo(
  ({
    onDateSelect,
    selectedTime,
    setSelectedTime,
    selectedDateInParent,
    setSelectedDateInParent,
    tempSelectedDate,
    setTempSelectedDate,
    currentWeek,
    setCurrentWeek
  }) => {
    // const [currentWeek2, setCurrentWeek2] = useState(startOfDay(new Date()));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [timePickerOpen, setTimePickerOpen] = useState(false);

    console.log("selectedDatezzz:", selectedDate);
    // console.log("currentWeek2:", currentWeek2);

    const [displayedDays, setDisplayedDays] = useState([]);
    // const [tempSelectedDate, setTempSelectedDate] = useState(new Date());
    const today = new Date();
    useEffect(() => {
      const daysOfWeek = Array.from({ length: 7 }).map((_, index) =>
        addDays(currentWeek, index)
      );

      const updateDisplayedDays = () => {
        if (window.innerWidth < 540) {
          setDisplayedDays(daysOfWeek.slice(0, 3));
        } else {
          setDisplayedDays(daysOfWeek);
        }
      };

      updateDisplayedDays();
      window.addEventListener("resize", updateDisplayedDays);

      return () => window.removeEventListener("resize", updateDisplayedDays);
    }, [currentWeek]);

    const handleNextWeek = () => {
      setCurrentWeek(addDays(currentWeek, 7));
    };

    const handlePreviousWeek = () => {
      setCurrentWeek(addDays(currentWeek, -7));
    };

    const handleDateClick = (date) => {
      setTempSelectedDate(date);
      setSelectedDate(date);
      onDateSelect(date);
      setSelectedDateInParent(date);
    };
    const handleDatePickerChange = (date) => {
      setTempSelectedDate(date);
      console.log("handleDatePickerChange:", date);
    };
    console.log("tempSelectedDate:", tempSelectedDate);
    const handleApply = () => {
      // handleDatePickerChange();
      // Set currentWeek to the start of the week containing the selected date
      setCurrentWeek(startOfWeek(tempSelectedDate, { weekStartsOn: 1 }));
      // setCurrentWeek2(startOfWeek(tempSelectedDate, { weekStartsOn: 1 }));
      setSelectedDate(tempSelectedDate);
      onDateSelect(tempSelectedDate);
      setDatePickerOpen(false);
      setSelectedDateInParent(tempSelectedDate);
    };

    console.log("tempSelectedDate:", tempSelectedDate);

    const handleReset = () => {
      setTempSelectedDate(today);
      setDatePickerOpen(false);
    };

    console.log("akus1:", selectedDate);
    console.log("akus2:", selectedDateInParent);

    return (
      <div className="flex justify-center items-center">
        <div className="flex items-center h-full lg:h-[87px] bg-primaryWhite rounded-xl shadow-lg font-semibold text-14 lgtext-16 py-2 lg:py-0">
          <div className="flex items-center px-2 lg:px-5">
            <div className="relative">
              <button
                onClick={() => {
                  setDatePickerOpen(!datePickerOpen);
                  setTimePickerOpen(false);
                }}
                className="text-primaryOrange hover:bg-primaryOrange hover:text-white transition-all ease-in-out duration-200 p-2 rounded-lg flex items-center gap-2"
              >
                <Calendar className="w-5 h-5 lg:w-8 lg:h-w-8 h-full" />
              </button>
              {datePickerOpen && (
                <div className="absolute top-full mt-2 bg-white shadow-lg rounded z-50">
                  <div className="p-4 rounded-xl bg-primaryWhite">
                    <DatePicker
                      selected={tempSelectedDate}
                      onChange={handleDatePickerChange}
                      inline
                      minDate={today}
                    />
                    <div className="flex gap-3">
                      <button
                        onClick={() => {
                          handleApply();
                        }}
                        className="px-4 py-2 bg-primaryOrange text-primaryWhite rounded-lg hover:bg-accentDarkOrange w-full flex items-center justify-center transition-all ease-out duration-200"
                      >
                        Terapkan
                      </button>
                      <button
                        onClick={handleReset}
                        className="px-4 py-2 bg-accentRed text-primaryWhite rounded-lg hover:bg-accentDarkOrange w-full flex items-center justify-center transition-all ease-out duration-200"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-3 border-l-2 border-r-2 border-primarySoftgray px-1 lg:px-5">
            <button
              onClick={handlePreviousWeek}
              className="hidden md:block p-1 rounded-lg text-primaryDarkgray hover:bg-primaryOrange hover:text-white transition-all ease-in-out duration-200"
            >
              <CaretLeft className="w-5 h-5 lg:w-8 lg:h-w-8 h-full" />
            </button>
            <div className="grid grid-cols-3 sm:grid-cols-7 md:grid-cols-7 lg:grid-cols-7 md:gap-2">
              {displayedDays.map((date, index) => (
                <button
                  key={index}
                  onClick={() => handleDateClick(date)}
                  className={`p-2 rounded-lg transition-all ease-in-out duration-300 flex flex-col items-center gap-1 ${
                    isSameDay(date, selectedDateInParent)
                      ? "bg-primaryOrange text-white"
                      : "text-primaryOrange hover:bg-primaryOrange hover:text-white hover:bg-opacity-85"
                  }`}
                >
                  <p>{format(date, "EEE", { locale: id })}</p>
                  <p>{format(date, "dd", { locale: id })}</p>
                </button>
              ))}
            </div>
            <button
              onClick={handleNextWeek}
              className="hidden md:block p-1 rounded-lg text-primaryDarkgray hover:bg-primaryOrange hover:text-white transition-all ease-in-out duration-200"
            >
              <CaretRight className="w-5 h-5 lg:w-8 lg:h-w-8 h-full" />
            </button>
          </div>
          <div className="px-2 lg:px-5">
            {" "}
            <div className="relative">
              <div
                className="flex flex-col lg:flex-row gap-1 lg:gap-3 items-center px-2 lg:px-5 text-primaryOrange hover:bg-primaryOrange hover:text-white transition-all ease-in-out duration-200 p-2 rounded-lg max-w-[65px] sm:max-w-full text-center cursor-pointer"
                onClick={() => {
                  setDatePickerOpen(false);
                  setTimePickerOpen(!timePickerOpen);
                }}
              >
                <Clock className="w-5 h-5 lg:w-8 lg:h-w-8 " />
                <span className="text-14 lg:text-16 hidden sm:block">
                  Filter Waktu
                </span>
              </div>
              <TimePicker
                timePickerOpen={timePickerOpen}
                setTimePickerOpen={setTimePickerOpen}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default TimeFilter;
