import { TrashSimple } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdvancedTable from "../../components/admin/AdvancedTable";
import ContinueLoader1 from "../../components/loaders/ContinueLoader1";
import AddTablePopup from "../../components/popup/addTablePopup";
import {
  deleteTransactionById,
  getAllTransaction
} from "../../redux/actions/transaction/transaction";
import { resetStateGetAllTransaction } from "../../redux/features/transaction/getAllTransactionSlice";
import { format } from "date-fns";

const Transaction = () => {
  const transactionColumns = [
    {
      header: "ID Transaksi",
      accessorKey: "transaksi_id"
    },
    {
      header: "Nama",
      accessorKey: "nama_penyewa"
    },
    {
      header: "No HP",
      accessorKey: "no_hp"
    },
    {
      header: "Lama Sewa",
      accessorKey: "total_lama_sewa",
      cell: ({ row }) => {
        return <>{row?.original?.total_lama_sewa} Jam</>;
      }
    },
    {
      header: "Tanggal",
      accessorKey: "tanggal_transaksi",
      cell: ({ row }) => {
        const timeStamp = row?.original?.tanggal_transaksi;

        return <>{format(new Date(timeStamp), "dd/MM/yyyy") || "-"}</>;
      }
    },
    {
      header: "Nominal",
      accessorKey: "total_harga",
      cell: ({ row }) => {
        return (
          <>
            {row?.original?.total_harga?.toLocaleString("id-ID", {
              style: "currency",
              currency: "IDR"
            })}
          </>
        );
      }
    },
    {
      header: "Meja",
      accessorKey: "nama"
    },
    {
      header: "Metode Bayar",
      accessorKey: "payment_method"
    },
    {
      header: "Bayar",
      accorsorKey: "status_transaksi",
      cell: ({ row }) => {
        return (
          <>
            {row?.original?.status_transaksi === "Success" ? (
              <div className="flex items-center justify-center text-accentGreen bg-accentSoftGreen  rounded-lg text-center py-2 w-full">
                Sudah
              </div>
            ) : row?.original?.status_transaksi === "Pending" ? (
              <div className="flex items-center justify-center text-primaryOrange bg-accentSoftOrange2 rounded-lg text-center py-2 w-full">
                Pending
              </div>
            ) : (
              <div className="flex items-center justify-center text-accentRed bg-accentSoftRed rounded-lg text-center py-2 w-full">
                Batal
              </div>
            )}
          </>
        );
      }
    },
    {
      header: "Aksi",
      accessorKey: "no",
      cell: ({ row }) => (
        <div className="flex items-center justify-center">
          <button
            onClick={() => {
              handleDeleteRow(row?.original?.transaksi_id);
            }}
            className="flex items-center justify-center px-3 py-2 rounded-xl border-2 border-primarySoftgray"
          >
            <TrashSimple className="text-accentRed" size={24} />
          </button>
        </div>
      )
    }
  ];

  const handleDeleteRow = (rowId) => {
    dispatch(deleteTransactionById(rowId)).then(() => {
      dispatch(getAllTransaction());
    });
  };

  const {
    getAllTransactionResponse,
    getAllTransactionLoading,
    getAllTransactionError,
    getAllTransactionSuccess
  } = useSelector((state) => state.getAllTransaction);

  const { deleteTransactionByIdLoading, deleteTransactionByIdSuccess } =
    useSelector((state) => state.deleteTransactionById);

  const [openAddTablePopup, setOpenAddTablePopup] = useState(false);
  const [selectedId, setSelectedId] = useState([]);

  const dispatch = useDispatch();
  const handleOpenAddtablePopup = () => {
    setOpenAddTablePopup(true);
  };

  useEffect(() => {
    dispatch(getAllTransaction());
    if (deleteTransactionByIdSuccess === true) {
      dispatch(getAllTransaction()).then(() => {
        dispatch(resetStateGetAllTransaction());
      });
    }
  }, [dispatch, deleteTransactionByIdSuccess]);

  const handleDeleteSelected = () => {
    selectedId?.forEach((element, i) => {
      dispatch(deleteTransactionById(element));
    });
  };

  const data = getAllTransactionResponse?.data;

  return (
    <div>
      <AddTablePopup
        isOpen={openAddTablePopup}
        onClose={() => {
          setOpenAddTablePopup(false);
        }}
      />
      <div className="flex-1 h-full">
        {getAllTransactionLoading ? (
          <div className="mt-40  flex items-center justify-center">
            <ContinueLoader1 />
          </div>
        ) : getAllTransactionError ? (
          <div className="mt-40  flex items-center justify-center">
            {getAllTransactionError}
          </div>
        ) : getAllTransactionResponse !== null ? (
          <AdvancedTable
            columns={transactionColumns}
            data={data}
            tableName={"Transaksi Masuk"}
            handleOpenAddtablePopup={handleOpenAddtablePopup}
            handleDeleteSelected={handleDeleteSelected}
            idNameToSelect={"transaksiId"}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Transaction;
